import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Confirm, MainProgress, Snackbars } from '@amaui/ui-react';
import { Style, Theme, AmauiStyle, AmauiTheme, unit, prefix, sort, rtl, valueObject, makeClassName } from '@amaui/style-react';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { FONT_FAMILY } from 'other';

import App from './App';

const valueStyle = new AmauiStyle();

valueStyle.plugins.add = [
  unit,
  prefix,
  sort,
  rtl,
  valueObject,
  {
    method: makeClassName,
    arguments: [
      {
        production: ['prod', 'production'].includes(process.env.NODE_ENV)
      }
    ]
  }
];

const valueTheme = new AmauiTheme({
  palette: {
    color: {
      amaui: {
        main: '#fafa00'
      }
    }
  },

  typography: {
    font_family: {
      primary: FONT_FAMILY.primary,
      secondary: FONT_FAMILY.secondary,
      tertiary: FONT_FAMILY.tertiary
    },

    values: {
      d1: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      d2: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      d3: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      h1: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      h2: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      h3: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      t1: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      t2: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      t3: { fontFamily: FONT_FAMILY.primary, fontWeight: 700 },

      l1: {
        fontFamily: FONT_FAMILY.secondary,
        fontWeight: 700
      },

      l2: {
        fontFamily: FONT_FAMILY.secondary,
        fontWeight: 700
      },

      l3: {
        fontFamily: FONT_FAMILY.secondary,
        fontWeight: 700
      },

      b1: { fontFamily: FONT_FAMILY.secondary },

      b2: { fontFamily: FONT_FAMILY.secondary },

      b3: { fontFamily: FONT_FAMILY.secondary },

      m1: { fontFamily: FONT_FAMILY.tertiary },

      m2: { fontFamily: FONT_FAMILY.tertiary },

      m3: { fontFamily: FONT_FAMILY.tertiary }
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('amaui-root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Style
      value={valueStyle}
    >
      <Theme
        value={valueTheme}
      >
        <Snackbars
          position='bottom'

          alignment='center'
        >
          <MainProgress>
            <Confirm>
              <App />
            </Confirm>
          </MainProgress>
        </Snackbars>
      </Theme>
    </Style>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister(); 
