import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { wrapper } from './other/utils';
import Root from './pages/Root';
import URL from './pages/URL';

const Routing = React.forwardRef((props: any, ref: any) => {
  const {
    ...other
  } = props;

  return (
    <Routes
      ref={ref}

      {...other}
    >
      <Route
        index

        element={wrapper(Root)}
      />

      <Route
        path='/url/:url'

        element={wrapper(URL)}
      />

      <Route
        path='*'

        element={<Navigate to='/' />}
      />
    </Routes>
  );
});

export default Routing;
