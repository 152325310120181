import { hash, is } from '@amaui/utils';
import AmauiSubscription from '@amaui/subscription';
import { IAddMany, IAdditional, IRemoveMany, IResponse, IUpdateMany } from '@amaui/sdk/other';
import { Query } from '@amaui/models';

import AmauiService from './amaui';
import { PAGINATION_LIMIT, getErrorMessage } from 'other';

export class BaseService<Model = undefined> {
  public paginationLimit = PAGINATION_LIMIT;

  constructor(
    public route?: string
  ) { }

  public queryObjects = (subscription: AmauiSubscription, method = 'queryPost') => async (body?: Partial<Query> | undefined, options?: IAdditional | undefined): Promise<IResponse> => {
    const query = { ...body };

    let loadMore = query.loadMore;

    // clear
    delete query.loadMore;

    const hashPrevious = hash(subscription.value?.query?.query);
    const hashNew = hash(body?.query);

    const isStripe = ['queryInvoicePost', 'queryPaymentMethodPost'].includes(method);

    if (!isStripe) {
      if (subscription.value?.query?.query === undefined || hashPrevious !== hashNew) query.total = true;
    }

    // limit
    if (query.limit === undefined) query.limit = this.paginationLimit;

    const result = await AmauiService.sdk[this.route as 'websites']?.[method as 'queryPost'](query as any, options);

    const previous = subscription.value;

    const refetch = (clear = false) => {
      const queryRefetch = { ...subscription.value?.query };

      if (clear) {
        const toRemove = ['next', 'previous', 'skip'];

        toRemove.forEach((item: string) => delete queryRefetch[item]);
      }

      this.queryObjects(subscription, method).bind(this)(queryRefetch, options);
    };

    let pagination = {
      ...previous?.pagination,
      ...result?.response?.pagination
    };

    let response = !loadMore ? result.response?.response : [
      ...previous?.response,
      ...result?.response?.response
    ];

    if (isStripe) {
      const {
        data,

        ...paginationStripe
      } = result?.response?.response;

      pagination = { ...paginationStripe };

      response = [
        ...(previous?.response || []),
        ...(data || [])
      ];
    }

    const update = (id: string, value: any, replace = false) => {
      if (is('array', response)) {
        const index = response.findIndex((item: any) => item?.id === id);

        if (index > -1) response[index] = replace ? value : {
          ...response[index],
          ...value
        };
      }
    };

    subscription.emit({
      loaded: true,
      query,
      status: result.status,
      error: getErrorMessage(result),
      meta: {
        ...previous?.meta,
        ...result?.response?.meta
      },
      pagination,
      response,
      update,
      refetch
    });

    return result;
  }

  public async add(body: any, options?: IAdditional) {
    return AmauiService.sdk[this.route as 'websites']?.add(body as any, options);
  }

  public async addMany(body: IAddMany<any>, options?: IAdditional) {
    return AmauiService.sdk[this.route as 'websites']?.addMany(body as any, options);
  }

  public async query(options?: IAdditional) {
    return AmauiService.sdk[this.route as 'websites']?.query(options);
  }

  public async queryPost(body?: Partial<Query>, options?: IAdditional) {
    return AmauiService.sdk[this.route as 'websites']?.queryPost(body as any, options);
  }

  public async get(id: string, options?: IAdditional) {
    return AmauiService.sdk[this.route as 'websites']?.get(id, options);
  }

  public async getAllPublic(id_organization: string, options?: IAdditional) {
    return AmauiService.sdk[this.route as 'tasks']?.getAllPublic(id_organization, options);
  }

  public async getPublic(id: string, options?: IAdditional) {
    return AmauiService.sdk[this.route as 'tasks']?.getPublic(id, options);
  }

  public async update(id: string, body: any, options?: IAdditional) {
    return AmauiService.sdk[this.route as 'websites']?.update(id, body as any, options);
  }

  public async updateMany(body: IUpdateMany<any>, options?: IAdditional) {
    return AmauiService.sdk[this.route as 'websites']?.updateMany(body as any, options);
  }

  public async remove(id: string, options?: IAdditional) {
    return AmauiService.sdk[this.route as 'websites']?.remove(id, options);
  }

  public async removeMany(body: IRemoveMany<Model>, options?: IAdditional) {
    return AmauiService.sdk[this.route as 'websites']?.removeMany(body as any, options);
  }

}
