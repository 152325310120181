import React from 'react';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';

import { getID } from '@amaui/utils';
import { Line, Reset, useMediaQuery } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';

import { AmauiService, StorageService } from 'services';
import config from 'config';
import { ErrorBoundary } from 'ui';
import Routes from './Routes';

const useStyle = style(theme => ({
  '@p': {
    body: {
      fontFamily: theme.typography.font_family.secondary,
      color: theme.palette.text.default.primary,
      backgroundColor: theme.palette.light ? theme.palette.color.primary[99] : theme.palette.color.primary[5],
      minHeight: '100vh',

      '&::before': {
        content: "''",
        background: '#dcfefb',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        backgroundImage: 'radial-gradient(circle at top left, hsl(60deg 100% 50%), transparent 400px), radial-gradient(circle at top right, #ffb200, transparent 30%), radial-gradient(at bottom left, #00ffe9, transparent 40%), radial-gradient(at bottom right, #9b00ff, transparent 400px), radial-gradient(at 60% 57%, #00ff99, transparent 44%)',
        opacity: 0.1
      },

      '& .amaui-Modal-surface': {
        '&.amaui-Surface-root': {
          background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
        }
      },

      '& .amaui-Modal-background:not(.amaui-Modal-background-invisible)': {
        background: theme.methods.palette.color.colorToRgb(theme.methods.palette.color.value('default', 10), 7)
      },

      '& .amaui-Tabs-root.amaui-Surface-root': {
        background: 'transparent',

        // '& hr': {
        //   display: 'none'
        // }
      }
    },

    a: {
      color: theme.palette.color.primary[30]
    }
  },

  root: {
    position: 'relative',
    minHeight: '100vh'
  }
}), { name: 'Root' });

const Main = () => {
  const { classes } = useStyle();

  const theme = useAmauiTheme();
  const light = useMediaQuery('(prefers-color-scheme: light)');

  const update = async (version = 'light', value: any = true) => {
    switch (version) {
      case 'light':
        theme.updateWithRerender({
          palette: {
            light: value
          }
        });

        StorageService.add('light', value);

        theme.inited = true;

        break;

      default:
        break;
    }
  };

  const init = React.useCallback(async () => {
    // device ID
    let deviceID = StorageService.get('device-id');

    if (!deviceID) {
      deviceID = getID();

      StorageService.add('device-id', deviceID);
    }

    AmauiService.init();

    if (config.value.production) {
      Sentry.init({
        dsn: config.value.services.sentry.dsn,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, []);

  // theme
  React.useEffect(() => {
    if (theme.palette.light !== light) update('light', light);
  }, [light, theme]);

  return <>
    <Reset />

    <Helmet>
      <title>amaui</title>
      <link rel='manifest' href={`/assets/favicon/${theme.palette.light ? 'light' : 'dark'}/site.webmanifest`} />
      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta name='apple-mobile-web-app-status-bar-style' content={theme.palette.light ? '#fafa00' : '#675C00'} />
      <meta name='theme-color' content={theme.palette.light ? '#fafa00' : '#675C00'} />
    </Helmet>

    <ErrorBoundary>
      <Line
        gap={0}

        direction='column'

        align='unset'

        justify='unset'

        fullWidth

        flex

        className={classNames([
          'amaui-App',

          classes.root
        ])}
      >
        <Routes />
      </Line>
    </ErrorBoundary>
  </>;
};

export default Main;
